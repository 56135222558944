import { functions } from '@/firebase';
import { IRemoteConfig } from '@/types';

const syncELF = functions.httpsCallable('syncELF');
const getConfig = functions.httpsCallable('getConfig');
const saveConfig = functions.httpsCallable('saveConfig');
const getSeries = functions.httpsCallable('getRankSeries');

const sync = () =>
  syncELF()
    .then((result) => result.data)
    .catch((error) => console.error(error));

const getElfConfig = () =>
  getConfig()
    .then((result: { data: any }) => result.data)
    .catch((error: any) => console.error(error));

const saveElfConfig = (data: IRemoteConfig) =>
  saveConfig(data)
    .then((result: { data: any }) => result.data)
    .catch((error: any) => console.error(error));

const getElfSeriesByRank = () =>
  getSeries()
    .then((result: { data: any }) => result.data)
    .catch((error: any) => console.error(error));

export { sync, getElfConfig, saveElfConfig, getElfSeriesByRank };
